// src/pages/contact.js
import * as React from 'react';
import Layout from '../components/layout';
import AccessSectionLeft from '../components/AccessSectionLeft/AccessSectionLeft';
import Seo from '../components/seo';

const ContactPage = ({ data }) => {
  return (
    <Layout>
      <AccessSectionLeft
        mapSrc="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d25917.226534206493!2d139.787932!3d35.710148!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188f658e355e19%3A0x91e42a434792d698!2zQVVTUElDReWFg-a1heiNiQ!5e0!3m2!1sja!2sus!4v1710485702907!5m2!1sja!2sus"
        title="お問い合わせ"
        subTitle="CONTACT"
        sectionId="contact"
        description={`
          メールアドレス：　info@siesta-tokyo.co.jp
          所在地：　東京都台東区元浅草4-10-6 AUSPICE元浅草9階
          電話番号：　03-5828-9523
          `}
        ctaLink="/contact"
      />
    </Layout>
  );
};

export default ContactPage;

export const Head = () => (
  <Seo
    title="お問い合わせ"
    description="
    お問い合わせはこちら。"
  />
);
